import { TPromoBanner } from '@services/store-admin/types';
import React from 'react'

export const HeroSlideImages = ({promo}:{promo: TPromoBanner}) => {    

  return (
    <> 
        <picture>            
            <source srcSet={`${promo.promo_banner_xs} 2x`} media="(max-width: 992px)" />
            <source srcSet={`${promo.promo_banner_xs} 2x`} media="(max-width: 412px)" />
            <source srcSet={`${promo.promo_banner_xs} 2x`} media="(max-width: 390px)" />
            <img src={promo.promo_banner} width="100%" title={promo.promo_alt} alt={promo.promo_alt} fetchPriority='high' loading='eager' />
        </picture>
    </>
  );
}